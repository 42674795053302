<template>
  <div>
    <div>
      <loader :isLoading="!data_loaded"></loader>
    </div>
    <div class="mt-2">
      <button class="mb-2 btn btn-default btn-sm" @click="$router.go(-1)">
        Back
      </button>
      <div class="row">
        <div class="col-md-12">
          <div class="card shadow w-100 p-4">
            <h3>Cash-Flow Management for {{ productData.name }}</h3>
            <div class="nav-wrapper">
              <ul
                class="nav nav-pills nav-fill flex-column flex-md-row"
                id="tabs-icons-text"
                role="tablist"
              >
                <li class="nav-item">
                  <a
                    class="nav-link mb-sm-3 mb-md-0 active"
                    id="tabs-icons-text-1-tab"
                    data-toggle="tab"
                    href="#tabs-icons-text-1"
                    role="tab"
                    aria-controls="tabs-icons-text-1"
                    aria-selected="true"
                    ><i class="ni ni-fat-delete mr-2"></i>Cash Out For {{ productData.name }}</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link mb-sm-3 mb-md-0"
                    id="tabs-icons-text-4-tab"
                    data-toggle="tab"
                    href="#tabs-icons-text-4"
                    role="tab"
                    aria-controls="tabs-icons-text-4"
                    aria-selected="false"
                    ><i class="ni ni-check-bold mr-2"></i>Cash in For {{ productData.name }}</a
                  >
                </li>
              </ul>
            </div>

            <div class="card shadow">
              <div class="card-body">
                <div class="tab-content" id="myTabContent">
                  <div
                    class="tab-pane fade show active bg-white"
                    id="tabs-icons-text-1"
                    role="tabpanel"
                    aria-labelledby="tabs-icons-text-1-tab"
                  >
                    <loader v-if="!data_loaded_cashout" />
                    <button
                      class="mb-2 btn btn-default btn-sm"
                      @click="navigateToCashFlow('out')"
                    >
                      Add cash out
                    </button>
                    <data-table
                      id="cashout"
                      v-if="data_loaded_cashout"
                      :options="dtb_options_cashout.extra_options"
                      :actions="dtb_options_cashout.actions"
                      :data="dtb_options_cashout.data"
                      :columns="dtb_options_cashout.columns"
                    />
                    <!-- display total cashout -->
                    <div class="row">
                      <div class="col-md-8"></div>
                      <div class="col-md-4">
                        <h2 v-if="total_cashout > 0">
                          Total cashout: {{ total_cashout }} RWF
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade bg-white"
                    id="tabs-icons-text-4"
                    role="tabpanel"
                    aria-labelledby="tabs-icons-text-4-tab"
                  >
                    <loader v-if="!data_loaded_in" />
                    <button
                      class="mb-2 btn btn-default btn-sm"
                      @click="navigateToCashFlow('in')"
                    >
                      Add cash in
                    </button>
                    <data-table
                      id="cash_in"
                      v-if="data_loaded_in"
                      :options="dtb_options_cashin.extra_options"
                      :actions="dtb_options_cashin.actions"
                      :data="dtb_options_cashin.data"
                      :columns="dtb_options_cashin.columns"
                    />
                    <div class="row">
                      <div class="col-md-8"></div>
                      <div class="col-md-4">
                        <h2 v-if="total_cashin > 0">
                          Total cashin: {{ total_cashin }} RWF
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { alert } from "../../utils/alertUtils";
import Loader from "../../components/Loader";
import {
  doc,
  deleteDoc,
  collection,
  getDocs,
  getDoc,
  query,
  where,
  addDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import DataTable from "@/components/Datatable";

export default {
  name: "ProductAccounting",
  components: {
    "data-table": DataTable,
    loader: Loader,
  },
  data() {
    return {
      data_loaded: false,
      productData: {},
      isActivate: true,
      data_loaded_cashout: false,
      data_loaded_in: false,
      total_cashin: 0,
      total_cashout: 0,
      dtb_options_cashout: {
        data: [],
        actions: [
          {
            btnText: "Delete",
            btnClass: "btn btn-white text-danger btn-sm",
            iconClass: "fas fa-trash",
            btnFn: (uuid) => {
              this.delete_transaction("cashout", uuid);
            },
            btnParamName: "uuid",
          },
        ],
        columns: [
          {
            title: "Added At",
            data: "date_inserted",
            render: (data) => {
              return data["date_inserted"]
                ? this.$date_format.fromFirestore(data["date_inserted"])
                : "N/A";
            },
          },
          { title: "Reason", data: "reason" },
          { title: "Source", data: "source" },
          {
            title: "Amount",
            data: "amount",
            render: (data) => `${data["amount"]}`,
          },
        ],
        extra_options: {},
      },
      dtb_options_cashin: {
        data: [],
        actions: [
          {
            btnText: "Delete",
            btnClass: "btn btn-white text-danger btn-sm",
            iconClass: "fas fa-trash",
            btnFn: (uuid) => {
              this.delete_transaction("cashin", uuid);
            },
            btnParamName: "uuid",
          },
        ],
        columns: [
          {
            title: "Added At",
            data: "date_inserted",
            render: (data) => {
              return data["date_inserted"]
                ? this.$date_format.fromFirestore(data["date_inserted"])
                : "N/A";
            },
          },
          { title: "Reason", data: "reason" },
          { title: "Source", data: "source" },
          {
            title: "Amount",
            data: "amount",
            render: (data) => `${data["amount"]}`,
          },
        ],
        extra_options: {},
      },
    };
  },
  methods: {
    navigateToCashFlow(status) {
      this.$router.push({
        name: 'Product CashFlow',
        params: {
          uuid: this.$route.params.uuid,
          status: status,
        },
      });
    },
    async fetch_cash_out() {
      this.data_loaded_cashout = false;
      try {
        const snapShots = await getDocs(collection(db, `products/${this.$route.params.uuid}/cashout`));
        this.dtb_options_cashout.data = [];
        this.total_cashout = 0;
        snapShots.forEach((document) => {
          let service = document.data();
          this.total_cashout += parseInt(service["amount"]);
          service.uuid = document.id;
          this.dtb_options_cashout.data.push(service);
        });
        this.data_loaded_cashout = true;
      } catch (error) {
        console.error(error);
        this.data_loaded_cashout = false;
        alert.error("An error occurred! Please try again.");
      }
    },
    async fetch_cash_in() {
      this.data_loaded_in = false;
      try {
        const snapShots = await getDocs(collection(db, `products/${this.$route.params.uuid}/cashin`));
        this.dtb_options_cashin.data = [];
        this.total_cashin = 0;
        snapShots.forEach((document) => {
          let service = document.data();
          this.total_cashin += parseInt(service["amount"]);
          service.uuid = document.id;
          this.dtb_options_cashin.data.push(service);
        });
        this.data_loaded_in = true;
      } catch (error) {
        console.error(error);
        this.data_loaded_in = false;
        alert.error("An error occurred! Please try again.");
      }
    },
    async fetch_product() {
      try {
        const ref = doc(db, "products", this.$route.params.uuid);
        const docSnap = await getDoc(ref);
        if (docSnap.exists()) {
          this.productData = docSnap.data();
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error(error);
        alert.error("An error occurred while fetching product data.");
      }
    },
    async delete_transaction(trans_status, uuid) {
      try {
        const docPath = `products/${this.$route.params.uuid}/${trans_status}`;
        const documentRef = doc(db, docPath, uuid);
        const documentSnapshot = await getDoc(documentRef);

        if (documentSnapshot.exists()) {
          const { amount, reason, source, user_id, date_inserted } = documentSnapshot.data();
          const newDocument = {
            action: "DELETE_TRANSACTION",
            amount,
            reason,
            source,
            user_id,
            date_inserted,
            trans_type: trans_status,
            doneAt: new Date(),
          };

          await addDoc(collection(db, "logs"), newDocument);
          await deleteDoc(documentRef);

          const collectionRef = collection(db, "cashflow");
          const q = query(collectionRef, where("prod_cashflow_ref", "==", uuid));
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            deleteDoc(doc.ref);
          });

          if (trans_status === "cashin") {
            await this.fetch_cash_in();
          } else {
            await this.fetch_cash_out();
          }

          alert.success_center("Deleted Successfully");
        } else {
          console.log("DOCUMENT WITH ID " + uuid + " NOT FOUND");
        }
      } catch (error) {
        console.error(error);
        alert.error("Error occurred in deleting this transaction");
      }
    },
  },
  async mounted() {
    try {
      await Promise.all([
        this.fetch_cash_out(),
        this.fetch_cash_in(),
        this.fetch_product(),
      ]);
      this.data_loaded = true;
    } catch (error) {
      console.error(error);
      alert.error("An error occurred while loading data.");
    }
  },
};
</script>

<style lang="css" scoped>
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #A7DA85;
}
.nav-pills .nav-link {
  color: #A7DA85;
}
.nav-pills .nav-link:hover {
  color: #A7DA85;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link:hover {
  color: white;
}
</style>